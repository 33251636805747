import gql from 'graphql-tag'

export const LOGIN_USER = gql`
mutation tokenCreate($email: String! $password: String!){
  tokenCreate(email: $email, password: $password) {
    errors {
      field
      message
    }
    token
    user {
      firstName
      lastName
      phone
      jobProfile {
        type
      }
    }
  }
}
`

export const ADD_CUSTOMER = gql`
mutation bwsDashboardUserCreate($firstName: String!, $lastName: String!, $phone: String!, $email: String!){
  bwsDashboardUserCreate(
      input: {firstName: $firstName, lastName: $lastName, phone: $phone, email: $email}
  ) {
      errors {
      field
      message
  }
  user {
    id
    firstName
    lastName
    phone
    email
  }
}
}   
`


export const CREATE_CAR = gql`
mutation bwsDashboardUserCarCreate($classification: CarClassificationEnum, $model: ID!, $year: Int!, $userId: String!, $licensePlate: String, $vin: String, $registrationRenewalAt:Date
  $insuranceRenewalAt:Date){
    bwsDashboardUserCarCreate(
      input: {classification: $classification, model: $model, year: $year, userId: $userId, licensePlate: $licensePlate, vin:$vin, registrationRenewalAt:$registrationRenewalAt
        insuranceRenewalAt:$insuranceRenewalAt}
    ) {
      errors {
        field
        message
      }
      car {
        id
        classification
        createdAt
        licensePlate
        model {
          make {
            name
          }
          name
        }
        vin
        year
        mileage
        mileageUpdatedAt
        nextServiceMileage
        nextServiceDue
      }
    }
  } 
`

export const UPDATE_CAR = gql`
mutation userCarUpdate($id:ID!, $year:Int!, $licensePlate:String, $vin:String, $mileage:Int, $nextServiceMileage:Int, $nextServiceDue:Date,$registrationRenewalAt:Date
  $insuranceRenewalAt:Date){
  userCarUpdate(
    input: {id:$id, year:$year, licensePlate:$licensePlate, vin:$vin, mileage:$mileage, nextServiceMileage:$nextServiceMileage, nextServiceDue:$nextServiceDue, registrationRenewalAt:$registrationRenewalAt
      insuranceRenewalAt:$insuranceRenewalAt}
  ) {
    errors {
      field
      message
    }
    car {
      id
      classification
      createdAt
      licensePlate
      model {
        make {
          name
        }
        name
      }
      vin
      year
      mileage
      mileageUpdatedAt
      nextServiceMileage
      nextServiceDue
    }
  }
}

`


export const UPDATE_USER = gql`

mutation userUpdate($id:String!, $firstName:String!, $lastName:String!, $notes: String, $email: String, $referrer: String!,) {
  userUpdate(input: {id: $id, firstName: $firstName, lastName: $lastName, notes: $notes, email: $email, referrer: $referrer}) {
    errors {
      field
      message
    }
    user {
      id
      firstName
      lastName
      phone
      email
    }
  }
}`


export const WASH_SUBSCRIPTION_CREATE = gql`
mutation bwsDashboardOfflineSubscriptionCreate(
  $carId: ID!, 
  $buildingId: ID, 
  $packageId: ID!, 
  $customerAddressId: ID, 
  $parkingSlot: String!, 
  $scheduledSlots: [ID!]!, 
  $amount:  Decimal!, 
  $currency: String!, 
  $userId: String!,
  $discount: Decimal!,
  $notes: String,
  $isCash: Boolean,
  $chargeAutomatically: Boolean,
  $voucherCode: String,
){
  bwsDashboardOfflineSubscriptionCreate(input: {
    carId: $carId, 
    buildingId: $buildingId, 
    packageId: $packageId, 
    customerAddressId: $customerAddressId, 
    parkingSlot: $parkingSlot, 
    scheduledSlots: $scheduledSlots, 
    total: {amount: $amount, currency: $currency}, 
    discount: {amount: $discount, currency: $currency},
    userId: $userId,
    notes: $notes,
    isCash: $isCash,
    chargeAutomatically: $chargeAutomatically,
    voucherCode: $voucherCode,
  }){
    errors{
      field
      message
    }
    washSubscription{
      id
      building{
        id
        company{
          legalName
          registeredAddress
        }
        name
        isArea
        location{
          lat
          lng
        }
        address
        contactPerson{
          firstName
          lastName
          phone
          email
        }
        shortCode
      }
      package{
        id
        type{
          name
          id
          slug
          weeklyWashes
          isActive
          rank
          description
        }
        intervalType
        numIntervals
      }
      customer{
        firstName
        lastName
        email
        phone
      }
      car{
        id
        model{
          id
          name
          make{
            name
            id
          }
        }
        year
        licensePlate
        vin
      }
      total{
        amount
        currency
      }
      tax{
        amount
        currency
      }        
      activatedAt
      pausedAt
      createdAt
      updatedAt
      validUpto
      unpausedAt
      paymentUrl
      completedAt
      cancelledAt
      lastReleaseAt
      nextReleaseAt
      status
      activationUrl
    }
  }
}`


export const CUSTOMER_ADDRESS_CREATE = gql`
mutation userAddressCreate($userId: String!, $lat: Float!, $lng: Float!, $address: String!, $label: String!){
  userAddressCreate(input: {userId: $userId, location: {lat: $lat, lng: $lng}, address: $address, label: $label}){
    errors {
      field
      message
    }
    address {
      id
      location {
        lat
        lng
      }
      address
      label
      createdAt
    }
  }
}
`

export const WASH_SUBSCRIPTION_UPDATE = gql`
mutation bwsDashboardOfflineSubscriptionUpdate(
  $washSubscriptionId: ID!, 
  $assignedToId: ID,
  $scheduledSlots: [ID!],
  $comment: String,
){
  bwsDashboardOfflineSubscriptionUpdate(input: {
    washSubscriptionId: $washSubscriptionId, 
    assignedToId: $assignedToId,
    scheduledSlots: $scheduledSlots,
    comment: $comment,
  }){
    errors{
      field
      message
    }
    washSubscription{
      id
      building{
        id
        company{
          legalName
          registeredAddress
        }
        name
        isArea
        location{
          lat
          lng
        }
        address
        contactPerson{
          firstName
          lastName
          phone
          email
        }
        shortCode
      }
      package{
        id
        type{
          name
          id
          slug
          weeklyWashes
          isActive
          rank
          description
        }
        intervalType
        numIntervals
      }
      customer{
        firstName
        lastName
        email
        phone
      }
      car{
        id
        model{
          id
          name
          make{
            name
            id
          }
        }
        year
        licensePlate
        vin
      }
      total{
        amount
        currency
      }
      tax{
        amount
        currency
      }        
      activatedAt
      pausedAt
      createdAt
      updatedAt
      validUpto
      unpausedAt
      paymentUrl
      completedAt
      cancelledAt
      lastReleaseAt
      nextReleaseAt
      status
      activationUrl
    }
  }
}`

